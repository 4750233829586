import { css, FlattenSimpleInterpolation } from 'styled-components'
import { theme } from '../theme'

/** Font family used in display fonts and headings */
export const displayFontFamily = css`
  font-family: neue-haas-grotesk-display, sans-serif;
`

/** Font family used in longer text or controls */
export const textFontFamily = css`
  font-family: neue-haas-grotesk-text, sans-serif;
`

/** Font weights for display fonts */
const displayFontWeight = {
  normal: theme.typography.weight.regular,
  semiBold: theme.typography.weight.semiBold,
  bold: theme.typography.weight.bold,
  heavy: theme.typography.weight.heavy
}

/** Font weights for text fonts */
const textFontWeight = {
  normal: theme.typography.weight.regular,
  semiBold: theme.typography.weight.semiBold,
  bold: theme.typography.weight.bold
}

/**
 * Font sizes used for "Display" fonts. Typically used for headings, hero
 * banners or call to actions.
 */
const displayFontSize = {
  ultra: css`
    font-size: 89px;
    line-height: 88px;
  `,
  xLarge: css`
    font-size: 67px;
    line-height: 72px;
  `,
  large: css`
    font-size: 50px;
    line-height: 56px;
  `,
  medium: css`
    font-size: 37px;
    line-height: 40px;
  `,
  small: css`
    font-size: 28px;
    line-height: 38px;
  `
}

/**
 * Font sizes used for "Text" fonts. Typically used for body copy, inputs,
 * controls, or lists.
 */
const textFontSize = {
  preHeading: css`
    font-size: 18px;
    line-height: 24px;
  `,
  bodyLarge: css`
    font-size: 21px;
    line-height: 31px;
  `,
  body: css`
    font-size: 17px;
    line-height: 24px;
  `,
  bodySmall: css`
    font-size: 13px;
    line-height: 16px;
  `,
  inputText: css`
    font-size: 16px;
    line-height: 16px;
  `,
  inputHelper: css`
    font-size: 13px;
    line-height: 15px;
  `,
  buttonFat: css`
    font-size: 17px;
    line-height: 1;
  `,
  button: css`
    font-size: 16px;
    line-height: 1;
  `,
  buttonThin: css`
    font-size: 14px;
    line-height: 1;
  `,
  caption: css`
    font-size: 13px;
    line-height: 15px;
  `,
  small: css`
    font-size: 12px;
    line-height: 14px;
  `,
  tiny: css`
    font-size: 11px;
    line-height: 13px;
  `,
  bulletListLarge: css`
    font-size: 20px;
    line-height: 24px;
  `,
  bulletList: css`
    font-size: 18px;
    line-height: 24px;
  `
}

type DisplayFontSize = keyof typeof displayFontSize
type DisplayFontWeight = keyof typeof displayFontWeight
type TextFontSize = keyof typeof textFontSize
type TextFontWeight = keyof typeof textFontWeight

/**
 * Returns the css necessary for a display font. It defaults to medium size with
 * normal weight.
 */
export function displayFont(
  size: DisplayFontSize = 'medium',
  weight: DisplayFontWeight = 'normal'
): FlattenSimpleInterpolation {
  return css`
    ${displayFontFamily}
    ${displayFontSize[size]}
    font-weight: ${displayFontWeight[weight]};
  `
}

/**
 * Returns the css necessary for a text font. It defaults to body size with
 * normal weight.
 */
export function textFont(
  size: TextFontSize = 'body',
  weight: TextFontWeight = 'normal'
): FlattenSimpleInterpolation {
  return css`
    ${textFontFamily}
    ${textFontSize[size]}
    font-weight: ${textFontWeight[weight]};
  `
}
