import * as React from 'react'
import IconClose from '../../images/icon-close.svg'
import * as HeaderStyle from './Header.styles'
import * as MenuModal from './MenuModal.styles'
import Logo from '../../images/bueno-analytics-logo.svg'
import {
  ABOUT_URL,
  GROCERY_URL,
  PARTNERS_URL,
  SOLUTIONS_URL,
  UNIVERSITIES_URL,
  CONTACT_URL
} from '../../constants/links'

// potential state: isDesktop and isOpen
// isDesktop controls the appearance of the header navigation
export const Header: React.FC = () => {
  const [isOpen, setMenuOpen] = React.useState(false)

  React.useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [isOpen])

  return (
    <HeaderStyle.Masthead>
      <HeaderStyle.Layout>
        <HeaderStyle.LayoutLeft>
          <HeaderStyle.BuenoLogo to="/">
            <img
              src={Logo}
              height="40"
              alt="Bueno Systems - Home"
              title="Home"
            />
          </HeaderStyle.BuenoLogo>
        </HeaderStyle.LayoutLeft>

        <HeaderStyle.LayoutCenter>
          <HeaderStyle.NavigationItem to="/">Home</HeaderStyle.NavigationItem>
          <HeaderStyle.NavigationItem to={SOLUTIONS_URL}>
            Solutions
          </HeaderStyle.NavigationItem>
          <HeaderStyle.NavigationItem>
            Industries ▾
            <HeaderStyle.SubMenu>
              <HeaderStyle.NavigationItem to={UNIVERSITIES_URL}>
                Universities
              </HeaderStyle.NavigationItem>
              <HeaderStyle.NavigationItem to={GROCERY_URL}>
                Grocery
              </HeaderStyle.NavigationItem>
            </HeaderStyle.SubMenu>
          </HeaderStyle.NavigationItem>
          <HeaderStyle.NavigationItem to={PARTNERS_URL}>
            Partners
          </HeaderStyle.NavigationItem>
          <HeaderStyle.NavigationItem to={ABOUT_URL}>
            About
          </HeaderStyle.NavigationItem>
          <HeaderStyle.NavigationItem to={CONTACT_URL}>
            Contact
          </HeaderStyle.NavigationItem>
        </HeaderStyle.LayoutCenter>

        <HeaderStyle.LayoutRight>
          <HeaderStyle.PrimaryCta isSmall to={CONTACT_URL}>
            Talk to an expert
          </HeaderStyle.PrimaryCta>
        </HeaderStyle.LayoutRight>

        <HeaderStyle.Hamburger onClick={() => setMenuOpen(true)}>
          Menu
        </HeaderStyle.Hamburger>

        {isOpen && (
          <MenuModal.Wrapper>
            <MenuModal.LayoutClose>
              <MenuModal.CloseButton onClick={() => setMenuOpen(false)}>
                <img src={IconClose} alt="Close menu" />
              </MenuModal.CloseButton>
            </MenuModal.LayoutClose>
            <MenuModal.Navigation>
              <ul>
                <li>
                  <MenuModal.NavigationItem to="/">
                    Home
                  </MenuModal.NavigationItem>
                </li>
                <li>
                  <MenuModal.NavigationItem to={SOLUTIONS_URL}>
                    Solutions
                  </MenuModal.NavigationItem>
                </li>
                <li>
                  <MenuModal.NavigationItem to={UNIVERSITIES_URL}>
                    Universities
                  </MenuModal.NavigationItem>
                </li>
                <li>
                  <MenuModal.NavigationItem to={GROCERY_URL}>
                    Grocery
                  </MenuModal.NavigationItem>
                </li>
                <li>
                  <MenuModal.NavigationItem to={PARTNERS_URL}>
                    Partners
                  </MenuModal.NavigationItem>
                </li>
                <li>
                  <MenuModal.NavigationItem to={ABOUT_URL}>
                    About
                  </MenuModal.NavigationItem>
                </li>
                <li>
                  <MenuModal.NavigationItem to={CONTACT_URL}>
                    Contact
                  </MenuModal.NavigationItem>
                </li>
              </ul>
            </MenuModal.Navigation>
            <MenuModal.Divider />
            <HeaderStyle.PrimaryCtaSmallScreen to={CONTACT_URL}>
              Talk To An Expert
            </HeaderStyle.PrimaryCtaSmallScreen>
          </MenuModal.Wrapper>
        )}
      </HeaderStyle.Layout>
    </HeaderStyle.Masthead>
  )
}
