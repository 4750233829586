import React from 'react'
import { Helmet } from 'react-helmet'
import { useSiteMetaData } from '../hooks/useSiteMetadata'

interface Props {
  /** Page title */
  title: string
}

export function Title({ title }: Props): JSX.Element {
  const metadata = useSiteMetaData()

  return (
    <Helmet>
      <title>
        {metadata.title} · {title}
      </title>
    </Helmet>
  )
}
