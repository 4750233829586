import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import { textFont } from '../styles/typography'

interface ButtonProps {
  /** Set to white */
  isReverse?: boolean
  /** Removes the minimum width and decreases the height */
  isSmall?: boolean
  /** Remove the minimum width */
  isInline?: boolean
  /** Background Colour */
  backgroundColor?: string
}

const buttonBorder = css`
  border-width: 1px;
  border-style: solid;
`

const defaultButton = css`
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : props.theme.palette.orange};
  border-color: ${props =>
    props.backgroundColor ? props.backgroundColor : props.theme.palette.orange};
  color: #fff;
`

const reverseButton = css`
  background-color: transparent;
  color: ${props => props.theme.color.black};
  border-color: rgba(0, 0, 0, 0.2);
`

// TODO: not sure if we need the pre-defined text style for these two call to action buttons
export const Button = styled(Link)<ButtonProps>`
  display: inline-flex;
  height: ${props => (props.isSmall ? '32px' : '40px')};
  align-items: center;
  justify-content: center;
  min-width: ${props =>
    props.isInline != null && props.isInline ? 'auto' : '120px'};
  padding: 0
    ${props =>
      props.isSmall ? props.theme.spacing.snug : props.theme.spacing.default};
  border-radius: 4px;
  text-decoration: none;
  ${buttonBorder}
  ${textFont('button', 'semiBold')};
  ${props =>
    props.isReverse != null && props.isReverse ? reverseButton : defaultButton}

  &:hover {
    cursor: pointer;
  }
`
