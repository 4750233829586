import { Link } from 'gatsby'
import styled from 'styled-components'
import { displayFont } from '../../styles/typography'
import { theme } from '../../theme'

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: ${theme.spacing.default};
  padding-right: ${theme.spacing.default};
  padding-bottom: ${theme.spacing.loose};
  padding-left: ${theme.spacing.loose};
  background-color: #fff;
  z-index: ${props => props.theme.layer.modal};
  overflow: hidden;
`

export const LayoutClose = styled.div`
  display: flex;
  justify-content: end;
`

export const CloseButton = styled.button`
  cursor: pointer;

  & > img {
    height: 48px;
    width: 48px;
  }
`

// links wrapper in the middle
export const Navigation = styled.nav`
  li {
    display: flex;
    align-items: center;
    margin-bottom: ${props => props.theme.spacing.tight};

    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const Divider = styled.div`
  height: 1px;
  background-color: ${theme.color.divider};
  margin-top: ${theme.spacing.loose};
  margin-bottom: ${theme.spacing.extraLoose};
`

// actual links
export const NavigationItem = styled(Link)`
  ${displayFont('small', 'normal')}
  padding: ${theme.spacing.tight} ${theme.spacing.snug};
  text-decoration: none;
  border-radius: ${theme.borderRadius.large};

  &:active {
    background-color: rgba(0,0,0,0.05);
  }
`
