import { Link } from 'gatsby'
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'
import { textFont } from '../../styles/typography'
import { Button } from '../../components/Button'
import { theme } from '../../theme'

export const Masthead = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${theme.layer.appNavigation};
  display: flex;
  align-items: center;
  height: ${theme.dimensions.masthead.height};
  background-color: #fff;
  padding: ${theme.spacing.default} ${theme.spacing.loose};
  box-shadow: ${theme.elevation.masthead};
`
export const Layout = styled.div`
  width: 100%;
  max-width: ${theme.dimensions.page.maxWidth};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`

export const LayoutLeft = styled.div`
  text-align: left;
  display: flex;
  flex-shrink: 1;
`

export const LayoutCenter = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 ${theme.spacing.loose};
  height: ${theme.dimensions.masthead.height};
`

export const LayoutRight = styled.div`
  display: flex;
  flex-shrink: 1;
  text-align: right;
`

export const BuenoLogo = styled(Link)`
  display: block;
  height: 40px;
`

export const SubMenu = styled.div`
  position: absolute;
  width: 160px;
  padding: ${theme.spacing.snug};
  background-color: #fff;
  border-radius: ${theme.borderRadius.large};
  box-shadow: ${theme.elevation.contentNavigation};
  visibility: hidden;
  opacity: 0;
  transition: opacity ${theme.animation.timing.default}
    ${theme.animation.easing.standard};
  /* create a small gap between main nav item and sub menu */
  /* submenu starts from the middle of the vertical space */
  /* then plus its own amount of padding to create a gap */
  top: calc(100% - 4px);
  left: 50%;
  transform: translateX(-50%);

  > a {
    padding: ${theme.spacing.snug};
    font-size: 14px;
    margin-left: 0;
  }
`

export const NavigationItem = styled(Link)`
  position: relative;
  ${textFont('button', 'semiBold')}
  text-decoration: none;
  color: ${theme.color.black};
  padding: ${theme.spacing.snug} ${theme.spacing.default};
  border-radius: ${theme.borderRadius.large};
  display: none;
  margin-left: ${theme.spacing.extraTight};

  &:first-child {
    margin-left: 0;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
    cursor: pointer;

    ${SubMenu} {
      visibility: visible;
      opacity: 1;
    }
  }

  @media (min-width: ${props => props.theme.breakpoint.desktop}) {
    display: block;
  }
`

function HamburgerDivider(bottom?: string): FlattenSimpleInterpolation {
  return css`
    content: '';
    position: absolute;
    bottom: ${bottom != null ? `${bottom}px` : 0};
    left: 0;
    width: 100%;
    height: 4px;
    display: block;
    background-color: black;
    border-radius: ${theme.borderRadius.small};
  `
}
export const Hamburger = styled.button`
  ${textFont('bodySmall', 'bold')}
  border: 0;
  background: none;
  position: relative;
  padding-bottom: ${theme.spacing.tight};
  margin-left: ${theme.spacing.default};
  margin-top: -8px;

  &:before {
    ${HamburgerDivider('0')}
  }

  &:after {
    ${HamburgerDivider('-8')}
  }

  &:hover {
    cursor: pointer;
  }

  @media (min-width: ${props => props.theme.breakpoint.desktop}) {
    display: none;
  }
`

// TODO: not sure if we need the pre-defined text style for these two call to action buttons
export const CallToActionButton = styled(Button)`
  ${textFont('buttonThin', 'semiBold')}
  height: 32px;
  min-width: auto;
  color: #fff;

  &:hover {
    background-color: ${theme.palette.orangeDark};
  }
`
export const PrimaryCta = styled(CallToActionButton)`
  @media (max-width: ${props => props.theme.breakpoint.smallScreen}) {
    display: none;
  }
`

export const PrimaryCtaSmallScreen = styled(CallToActionButton)`
  ${textFont('button', 'semiBold')}
  height: 40px;
`
