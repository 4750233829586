import * as React from 'react'
import styled from 'styled-components'
import { theme } from '../theme'

export const Section = styled.section`
  margin: 0 auto;
  max-width: ${theme.dimensions.app.maxWidth};
  padding-right: ${props => props.theme.spacing.loose};
  padding-left: ${props => props.theme.spacing.loose};

  @media screen and (min-width: ${props =>
      props.theme.breakpoint.mediumScreen}) {
    padding-right: ${props => props.theme.spacing.extraLoose};
    padding-left: ${props => props.theme.spacing.extraLoose};
  }

  @media screen and (min-width: ${props => props.theme.breakpoint.desktop}) {
    padding-right: ${props => props.theme.spacing.ultraLoose};
    padding-left: ${props => props.theme.spacing.ultraLoose};
  }
`

const BackgroundColor = styled.section`
  background-color: ${theme.color.appBg};
`
export const FullWidthSection: React.FC = ({ children }) => (
  <BackgroundColor>
    <Section as="div">{children}</Section>
  </BackgroundColor>
)
