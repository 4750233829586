import { Link } from 'gatsby'
import styled from 'styled-components'
import { textFont, displayFont } from '../../styles/typography'
import { Grid } from '../../components/Grid'
import { theme } from '../../theme'

export const Footer = styled.footer`
  background-color: ${theme.color.appBg};
  ${textFont('bodyLarge', 'normal')}

  a {
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
`

export const LayoutGrid = styled(Grid)`
  padding-top: ${theme.spacing.loose};
  padding-bottom: ${theme.spacing.loose};
  align-items: start;

  @media (min-width: ${props => props.theme.breakpoint.mediumScreen}) {
    padding-top: ${theme.spacing.extraLoose};
    padding-bottom: ${theme.spacing.extraLoose};
  }
`
export const LayoutLeft = styled.div`
  grid-column-start: 1;
  grid-column-end: span 4;

  @media (min-width: ${props => props.theme.breakpoint.mediumScreen}) {
    grid-column-end: span 2;
  }
`
export const LayoutRight = styled.div`
  grid-column-start: 1;
  grid-column-end: span 4;
  border-top: 1px solid ${theme.color.divider};
  padding-top: ${theme.spacing.loose};

  @media (min-width: ${props => props.theme.breakpoint.mediumScreen}) {
    border-left: 1px solid ${theme.color.divider};
    padding-top: 0;
    grid-column-start: 3;
    grid-column-end: -1;
    border-top: none;
  }
`

export const ContentRight = styled.div`
  @media (min-width: ${props => props.theme.breakpoint.mediumScreen}) {
    padding: 0 ${theme.spacing.extraLoose};
  }
`

export const NavItems = styled.ul`
  margin: ${theme.spacing.extraLoose} 0;

  li + li {
    margin-top: ${props => props.theme.spacing.tight};
  }

  li > a {
    display: inline-flex;
    align-items: center;

    img {
      margin-right: ${props => props.theme.spacing.tight};
      width: 32px;
    }
  }
`

export const BuenoLogo = styled(Link)`
  display: block;
  height: 32px;
  margin-bottom: ${theme.spacing.loose};
`

export const Navigation = styled.nav``

export const ContentRightHeader = styled.h3`
  margin-bottom: ${props => props.theme.spacing.tight};
  ${displayFont('small', 'bold')}
`

export const ContentRightBody = styled.p`
  margin-bottom: ${props => props.theme.spacing.default};
  ${textFont('bodyLarge', 'normal')}
`

export const ImageMap = styled.img`
  margin-top: ${props => props.theme.spacing.extraLoose};
  margin-bottom: ${props => props.theme.spacing.extraLoose};
  width: 100%;
  height: auto;
`

export const Copyright = styled.div`
  grid-column-start: 1;
  grid-column-end: -1;
  padding-top: ${theme.spacing.loose};
  ${textFont('bodySmall', 'normal')}
  color: ${props => props.theme.typography.color.subdued};
  text-align: left;
  border-top: 1px solid ${theme.color.divider};
`
