import styled from 'styled-components'
import { Section } from './Section'

/**
 * Base grid style for the entire website
 */
export const Grid = styled(Section)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: ${props => props.theme.spacing.loose};

  @media (min-width: ${props => props.theme.breakpoint.mediumScreen}) {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    column-gap: ${props => props.theme.spacing.loose};
    align-items: center;
  }

  @media (min-width: ${props => props.theme.breakpoint.desktop}) {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    column-gap: ${props => props.theme.spacing.extraLoose};
    align-items: center;
  }
`
