import * as React from 'react'
import { Link } from 'gatsby'
import * as Styles from './Footer.styles'
import ImageMap from '../../images/locations-map.png'
import LinkedIn from '../../images/social-linkedin.png'
import Insta from '../../images/social-instagram.png'
import {
  ABOUT_URL,
  GROCERY_URL,
  PARTNERS_URL,
  SOLUTIONS_URL,
  UNIVERSITIES_URL,
  CONTACT_URL
} from '../../constants/links'
import Logo from '../../images/bueno-analytics-logo.svg'

export const Footer: React.FC = () => {
  return (
    <Styles.Footer>
      <Styles.LayoutGrid>
        <Styles.LayoutLeft>
          <Styles.Navigation>
            <Styles.BuenoLogo to="/">
              <img
                src={Logo}
                height="32"
                alt="Bueno Systems - Home"
                title="Home"
              />
            </Styles.BuenoLogo>
            <Styles.NavItems>
            <li>
              <Link to={SOLUTIONS_URL}>Solutions</Link>
            </li>
            <li>
              <Link to={UNIVERSITIES_URL}>Universities</Link>
            </li>
            <li>
              <Link to={GROCERY_URL}>Grocery</Link>
            </li>
            <li>
              <Link to={PARTNERS_URL}>Partners</Link>
            </li>
            <li>
              <Link to={ABOUT_URL}>About</Link>
            </li>
            <li>
              <Link to={CONTACT_URL}>Contact</Link>
            </li>
            </Styles.NavItems>
            <Styles.NavItems>
            <li>
              <a
                href="https://au.linkedin.com/company/buenosystems"
                target="_blank"
                rel="noreferrer"
              >
                <img src={LinkedIn} alt="Bueno LinkedIn" />
                LinkedIn
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/buenoanalytics/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Insta} alt="Bueno - Instagram" />
                Instagram
              </a>
            </li>
            </Styles.NavItems>
          </Styles.Navigation>
        </Styles.LayoutLeft>

        <Styles.LayoutRight>
          <Styles.ContentRight>
            <Styles.ContentRightHeader>
              Join the rest our global partners & clients in creating a
              sustainable future for your business.
            </Styles.ContentRightHeader>

            <Styles.ContentRightBody>
              Our systems are found across the; USA, Canada, UK, Mexico,
              Australia & Asia
            </Styles.ContentRightBody>

            <Styles.ImageMap src={ImageMap} />
          </Styles.ContentRight>
        </Styles.LayoutRight>

        <Styles.Copyright>
          Copyright Built Environment Optimisation Pty Ltd{' '}
          {new Date().getFullYear()}. All rights reserved.
        </Styles.Copyright>
      </Styles.LayoutGrid>
    </Styles.Footer>
  )
}
