import React, { FC } from 'react'
import { Footer, Header } from '../modules'
import styled from 'styled-components'
import { theme } from '../theme'

const Main = styled.main`
  margin-top: ${theme.dimensions.masthead.height};
  margin-bottom: 112px;
`

export const PageLayout: FC = ({ children }) => {
  return (
    <>
      <Header />

      <Main>{children}</Main>

      <Footer />
    </>
  )
}
